$(document).ready(function () {

    init();
    function init() {
        /**
         * Manage the footable events to prevent bad visual effects when the table is loading.
         */
        $('#table_crons').bind({
            'footable_initialized': function () {
                $('#loadingJobs').addClass('hide');
                $('#pendingJobs').removeClass('hide');
                $('#overlay_footable').hide();
                removeOverlayDiv();
                let spans = $('.span-intervals');
                $.each(spans, function (indexSpan, span) {
                    let intervals = $(span).attr('data-intervals').split(' ');
                    let msg = '';
                    $.each(intervals, function (index, interval) {
                        let class_part = '';
                        switch (index) {
                            case 0:
                                class_part = 'cron-minute';
                                break;
                            case 1:
                                class_part = 'cron-hour';
                                break;
                            case 2:
                                class_part = 'cron-day-month';
                                break;
                            case 3:
                                class_part = 'cron-month';
                                break;
                            case 4:
                                class_part = 'cron-day-week';
                                break;
                        }
                        msg += parseCronExpresion(interval, class_part) + ' ';
                    });

                    $(span).attr('data-original-title', msg);
                });
                $('[data-toggle="tooltip"]').tooltip();
            },
            'footable_row_collapsed': function (e) {
                if ($(e.row).find('.sub-crons').children().length > 1) {
                    $(e.row).find('.footable-first-column').html('<span data-icon="2"></span>');
                }
            },
            'footable_row_expanded': function (e) {
                if ($(e.row).find('.sub-crons').children().length > 1) {
                    $(e.row).find('.footable-first-column').html('<span data-icon="1"></span>');
                } else {
                    let row_detail = $(e.row).next();
                    if (row_detail.hasClass('footable-row-detail')) {
                        row_detail.remove();
                    }
                }
            },
            'footable_toggle_row': function (e) {
                $('.footable-row-detail-name').remove();
            }

        });

        /**
         * Fires a modal when the button add cron job is clicked. In this function the cronjob_edit view is adapted
         * to the modal structure.
         */
        $(document).on('click', '#add-cron-job', function (e) {
            e.preventDefault();
            let url = this.href;
            let fullUrl = url;
            if ($(this).data('is-instance-view') != undefined) {
                let data = {
                    is_instance_view: $(this).data('is-instance-view'),
                    group_pattern: $(this).data('cron-group')
                };
                let queryString = $.param(data);
                fullUrl = url + "?" + queryString;
            }
            $('#modal').css('max-width', '60%');
            $('#modal').modal();
            $('#modal').load(fullUrl, function () {

                let header = $('#modal').find('.tablestyles-header').not('.header-logs').remove();
                let footer = $('#modal').find('.tablestyles-footer').remove();
                let body = $('#modal').find('.tablestyles-body').remove();
                let form = $('#cronjobs-form').remove();

                $("#modal").append(form);

                $("#cronjobs-form").append('<div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"></div>');
                $("#modal .modal-header").append('<h3>' + $(header).text() + '</h3>');
                $("#modal .modal-body").append($(body).html());
                $("#modal .modal-footer").append($(footer).html());

                $('#add-cronjob-cancel').attr('href', '#');
                $('#add-cronjob-cancel').attr('data-dismiss', 'modal');
            });
        });

        /**
         * Fires a modal when the button delete cron job is clicked. In the list of cronjobs.
         */
        $(document).on('click', '.delete-cron-job', function (e) {
            e.preventDefault();
            if ($(this).data('view') !== undefined) {
                $('#delete-cronjob-confirm').attr('data-view', $(this).data('view'));
                $('#delete-cronjob-confirm').attr('data-cron-parent', $(this).data('cron-parent'));
            }
            $('#delete-cronjob-confirm').attr('data-cronjob-id', $(this).attr('data-cronjob-id'));
            $('#delete-cronjob-confirm').attr('href', this.href);
            $("#modal").css("max-width", "");
            $('#modal').html($('#modal-cron').html());
            $('#modal').modal();
        });

        /**
         * Catch the delete confirm event in the modal delete cronjob. Catch de cron id and send a request to eliminate
         * the current cronjob.
         */
        $(document).on('click', '#delete-cronjob-confirm', function (e) {
            e.preventDefault();
            let href = $(this).attr('href');
            let id = $(this).data('cronjob-id');
            let view = $(this).data('view');
            let cron_parent_id = $(this).data('cron-parent');

            $.ajax({
                url: href,
                method: 'POST',
                data: {
                    id: parseInt(id)
                },
                beforeSend: function () {
                },
                success: function (msg, status, xhr) {
                    $('#modal').modal('hide');
                    let instances = $('#table_cronjobs_instances').find('tbody tr');
                    if (view === undefined) {
                        reloadCronjobsTable();
                    } else if(view === 'instances') {
                        if ($('#table_cronjobs_instances').find('tbody tr').length !== 0 ) {
                            reloadCronjobsInstancesTable(cron_parent_id);
                        }
                        if (id === cron_parent_id) {
                            window.location.href = '/settings/cron-job';
                        }
                    } else {
                        window.location.href = '/settings/cron-job';
                    }
                    $.growl.notice({title: "Success", message: "The cronjob was deleted."});
                },
                error: function (msg, status, xhr) {
                    $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                }
            });
        });
        /**
         *  Handle the click event on the list of updated products in a cron run and show all changes in a view.
         */
        $(document).on('click', '.product-info', function (e) {
            e.preventDefault();
            let cron_history = $(this).data('cron');
            let product = $(this).data('product');
            $.ajax({
                url: '../../cron-job/product/detail/',
                data: {
                  cronjob: cron_history,
                  product_uuid: product,
                  is_history: false
                },
                method: 'GET',
                beforeSend: function () {
                    $('#pendingJobs').append(overlayDiv());
                },
                success: function (msg, status, xhr) {
                    $('#pendingJobs').find('overlay_footable').remove();
                },
                error: function (msg, status, xhr) {
                    $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                },
                complete: function(data){
                    $('#product_detail').append(data.responseText);
                    $('#close-product-detail').show();
                    $('#container_table-products').hide();
                    $('#product_detail').show('slow');
                    $('#product_info').show('slow');
                    $('#overlay_footable').hide();
                    removeOverlayDiv();
                    $('.product_details').each(function () {
                        $(this).footable('footable');
                        $(this).footableCustom('footable');
                    });
                }
            });
        });
        /**
         *  Handle the click event on the list of updated products history and show all changes in a view.
         */
        $(document).on('click', '.product-history-info', function (e) {
            e.preventDefault();
            let cron_history = $(this).data('cron');
            let product = $(this).data('product');
            let data_view = $(this).data('view');
            $.ajax({
                url: '../../cron-job/product/detail/',
                method: 'GET',
                data: {
                    cronjob: cron_history,
                    product_uuid: product,
                    is_history: true
                },
                beforeSend: function () {
                    $('#pendingJobs').append(overlayDiv());
                },
                success: function (msg, status, xhr) {
                    removeOverlayDiv();
                },
                error: function (msg, status, xhr) {
                    $.growl.error({title: "Error", message: 'The action failed.' + msg.statusText});
                    removeOverlayDiv();
                },
                complete: function(data){
                    let $table = $(data.responseText);
                    $('#product_detail_history').append($table);
                    $('#product_detail_history').show();
                    $('#product_info_history').show('slow');
                    $('.product_detail_history').each(function () {
                        $(this).footable('footable');
                        $(this).footableCustom('footable');
                    });
                    if (data_view == 'product-history') {
                        $('#container_table-products-history').hide('slow');
                    } else if (data_view == 'cronjobs_instances') {
                        $('#container_table-products').hide('slow');

                    }
                }
            });
        });


        /**
         *  Handles the click event on the list of dates in the cron execution history and shows the products updated
         *  on the selected date.
         */
        $(document).on('click', '.history_date' , function (e) {
            e.preventDefault();
            let date = $(this).data('date');
            let cron_id = $(this).data('cron');
            $.ajax({
                url: '../../cron-job/product/getCronJobHistoryByDate',
                method: 'GET',
                data: {
                    date: date,
                    cron_id: cron_id
                },
                beforeSend: function () {
                    $('#pendingJobs').append(overlayDiv());
                },
                success: function (msg, status, xhr) {
                },
                error: function (msg, status, xhr) {

                },
                complete: function (data) {
                    let $table = $(data.responseText);
                    $table.attr('id', 'table-products-history');
                    $table.attr('data-filter', '#table-products-history_filter');
                    $('#cronjob_history').find('.tablestyles-body').append($table);
                    $('#table-products-history').footable('footable');
                    $('#table-products-history').footableCustom('footable');
                    $('.tablestyles-header.history-header>h4').append(' <small id="history_date_title" style="color: #8553BA">('+ date + ')</small>')
                    $('#close-cron-product-history').show();
                    $('#container_table_product_dates').hide('slow');
                    removeOverlayDiv();
                }
            });
        });

        /**
         * Raise a modal with the execution changes in the cronjob.
         */
        $(document).on('click', '.view-changes', function (e) {
            e.preventDefault();
            let type = $(this).data('type');
            let key = $(this).data('key');
            let cron_id = $(this).data('cron');
            $.ajax({
                url: '../../cron-job/product/attrDiff',
                method: 'GET',
                data: {
                    cron_id: cron_id,
                    key: key,
                    type: type
                },
                beforeSend: function () {
                    let id = 'maincontent';
                    if ($('input[name="cron_id"]').val() === "") {
                        id = 'modal';
                    }
                    $('#'+id).append(overlayDiv());
                },
                success: function (msg, status, xhr) {
                    removeOverlayDiv();
                    $('#modal').html(msg);
                    $('#modal').modal();
                },
                error: function (msg, status, xhr) {
                    removeOverlayDiv();
                    $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                },
                complete: function (data) {
                    // $.growl.notice({title: "Success", message: 'The cronjob was created.' + data.statusText});
                }
            });
        });

         /**
         * Handle the click event and close the product detail information.
         */
         $(document).on('click', '#close-product-detail', function (e) {
            e.preventDefault();
            $(this).hide();
            $('#container_table-products').show('slow');
            $('#product_detail').hide();
            $('#product_info').hide('slow');
            $('#product_detail').empty();
        });

        /**
         * Manage the close click event in the tab section history, when is selected an specific date.
         */
        $(document).on('click', '#close-cron-product-history', function (e) {
            e.preventDefault();
            $(this).hide();
            $('#history_date_title').remove();
            $('#container_table-products-history').empty();
            $('#container_table-products-history').show();
            $('#product_detail_history').empty();
            $('#container_table_product_dates').show('slow');
        })

        /**
         * Handles the input event in the cron scheduling input in the creation and editing form.
         */
        $(document).on('input', '.cron-input', function (event) {
            var inputs = $('.cron-input');
            var index = inputs.index(this);

            //If the lenght of value  is the max permitted jump to the next input.
            if ($(this).val().length >= parseInt($(this).attr('maxlength'))) {
                if (index < inputs.length - 1) {
                    inputs.eq(index + 1).focus();
                }
            }

            // If the value is * jump to the next input
            if ($(this).val() === '*') {
                inputs.eq(index + 1).focus();
            }

            $('.clickable').removeClass('part-selected');
            $('.info-part ').removeClass('part-selected');
            $('.info-validation').hide();
            selection(this.id, this.value);
        });

        /**
         * Handles the click event in the cron scheduling input in the creation and editing form..
         */
        $(document).on('click', '.cron-input', function (e) {
            $('.clickable').removeClass('part-selected');
            $('.info-part ').removeClass('part-selected');
            $('.info-validation').hide();
            selection(this.id, this.value);
        });

        /**
         *  Capture the submit event for the editing form and creating a cronjob, to send it via ajax.
         */
        $(document).on('submit', '#cronjobs-form', function (e) {
            e.preventDefault();
            let action = $('#cronjobs-form').attr('action');
            let formData = $('#cronjobs-form').serialize();
            $.ajax({
                url: action,
                method: 'POST',
                data: formData,
                beforeSend: function () {
                    let id = 'maincontent';
                    if ($('input[name="cron_id"]').val() === undefined) {
                        id = 'modal';
                    }
                    $('#'+id).append(overlayDiv());
                },
                success: function (msg, status, xhr) {

                },
                error: function (msg, status, xhr) {
                    $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                },
                complete: function (data) {
                    let is_instance_view = 'none';
                    let cronscheduling_id = 'none';
                    try {
                        is_instance_view = JSON.parse(data.responseText).is_instance_view;
                        cronscheduling_id = JSON.parse(data.responseText).cron_id;
                    } catch (e) {
                    }
                    reload();
                    let word_action = 'updated';
                    if ($('input[name="cron_id"]').val() === undefined) {
                        $('#modal').modal('hide');
                        reloadCronjobsTable();
                        word_action = 'created';
                        removeOverlayDiv();
                    } else {
                        removeOverlayDiv();
                    }
                    if (is_instance_view != 'none') {
                        $('#modal').modal('hide');
                        reloadCronjobsInstancesTable(cronscheduling_id);
                    }
                    $.growl.notice({title: "Success", message: 'The cronjob was ' + word_action + '. ' + data.statusText});
                }
            });
        });

        /**
         * Manage the close modal event to rollback the click action in the active cronjob toggle button.
         */
        $(document).on('click', '#close-switcher-active-list', function (e) {
            var switcher = $('#close-switcher-active-list').attr('data-switcher');
            // RollBack the toggle button action when the action of activate or deactivate cronjobs is cancelled.
            let modal_active_cronjob = $('#modal-activate-cronjob');

            //Check if the current modal is the cronjob activation modal.
            if (modal_active_cronjob.length > 0) {
                let active = $('#' + switcher + '> button.active');
                let inactive = $('#' + switcher + '>  button:not(.active)');
                $(active).removeClass('active');
                $(inactive).addClass('active');
            }
        });

        /**
         * Manage the close modal event to rollback the click action in the active cronjob toggle button.
         */
        $(document).on('click', '#close-switcher-active-all', function (e) {
            var switcher = $('#close-switcher-active-all').attr('data-switcher');
            // RollBack the toggle button action when the action of activate or deactivate cronjobs is cancelled.
            let modal_active_cronjob = $('#modal-activate-cronjob');

            //Check if the current modal is the cronjob activation modal.
            if (modal_active_cronjob.length > 0) {
                let active = $('#' + switcher + '> button.active');
                let inactive = $('#' + switcher + '>  button:not(.active)');
                $(active).removeClass('active');
                $(inactive).addClass('active');
            }
        });

        /**
         * Change the input hidden value when the toggle button is changed.
         */
        $(document).on('click', '.switcher-active-form', function (e) {
            let clicked_val = $(e.target).data('val');
            let input_hidden = $(e.target).siblings('input');
            input_hidden.val(clicked_val);
        });


        /**
         * Fires a modal when a switcher button is clicked to activate or deactivate a cronjob.
         */
        $(document).on('click', '.switcher-active-list', function (e) {
            let switcher = $(this);
            let current_active = $(this).data('current');
            let clicked_val = $(e.target).data('val');
            let view = $(this).data('view');
            if(current_active != clicked_val) {
                let activate_word = clicked_val == 0 ? 'deactivate' : 'activate';
                let modal_confirm =
                    '    <div id="modal-activate-cronjob" class="modal-header" style="padding-bottom:0;"><h3>Confirmation</h3></div>\n' +
                    '    <div class="modal-body">\n' +
                    '        <p>\n' + 'Are you sure you want to <strong style="color: #8553BA">' + activate_word + '</strong> the cronjob?</p>\n' +
                    '        <input id="clicked-val" type="hidden" value="'+ clicked_val +'">\n' +
                    '    </div>\n' +
                    '    <div class="modal-footer">\n' +
                    '      <button id="send-switcher-active-list" data-switcher-clicked="'+ clicked_val +'" class="btn btn-primary">Yes</button>\n' +
                    '      <button id="close-switcher-active-list" class="btn" data-dismiss="modal">No</button>\n' +
                    '    </div>\n';
                $('#modal').html(modal_confirm);
                $('#modal').find('#close-switcher-active-list').attr('data-switcher', $(switcher).attr('id'));
                $('#modal').find('#send-switcher-active-list').attr('data-switcher', $(switcher).attr('id'));
                $('#modal').find('#send-switcher-active-all').attr('data-view', view);
                $('#modal').modal('show');
            }
        });

        /**
         * Fires a modal when a global switcher button is clicked to activate or deactivate a cronjob.
         */
        $(document).on('click', '.switcher-active-all', function (e) {
            let switcher = $(this);
            let current_active = $(this).data('current');
            let view = $(this).data('view');
            let cronjob_parent = $(this).data('cronjob_parent');
            let clicked_val = $(e.target).data('val');
            if(current_active != clicked_val) {
                let activate_word = clicked_val == 0 ? 'deactivate' : 'activate';
                let modal_confirm =
                    '    <div id="modal-activate-cronjob" class="modal-header" style="padding-bottom:0;"><h3>Confirmation</h3></div>\n' +
                    '    <div class="modal-body">\n' +
                    '        <p>\n' + 'Are you sure you want to <strong style="color: #8553BA">' + activate_word + '</strong> all the cronjobs?</p>\n' +
                    '        <input id="clicked-val" type="hidden" value="'+ clicked_val +'">\n' +
                    '    </div>\n' +
                    '    <div class="modal-footer">\n' +
                    '      <button id="send-switcher-active-all" data-switcher-clicked="'+ clicked_val +'" class="btn btn-primary">Yes</button>\n' +
                    '      <button id="close-switcher-active-all" class="btn" data-dismiss="modal">No</button>\n' +
                    '    </div>\n';
                $('#modal').html(modal_confirm);
                $('#modal').find('#close-switcher-active-all').attr('data-switcher', $(switcher).attr('id'));
                $('#modal').find('#send-switcher-active-all').attr('data-switcher', $(switcher).attr('id'));
                $('#modal').find('#send-switcher-active-all').attr('data-view', view);
                if (cronjob_parent != undefined) {
                    $('#modal').find('#send-switcher-active-all').attr('data-cronjob_parent', cronjob_parent);
                }
                $('#modal').modal('show');
            }
        });

        /**
         * Catch the switch event when a cronjob is actiavted or deactivated, and send the request to the server.
         */
        $(document).on('click', '#send-switcher-active-list', function (e) {
            e.preventDefault();
            let switcher = $('#send-switcher-active-list').data('switcher');
            let selected = $(this).data('switcher-clicked');
            let id = $('#' + switcher).data('cronjobid');
            let view = $(this).data('view');
            $.ajax({
                url: '/settings/cron-job/activate',
                method: 'POST',
                data: {
                    cron_id: id,
                    active: selected,
                    is_running: (selected === 1) ? 0 : selected
                },
                beforeSend: function () {
                    $('#pendingJobs').hide();
                    $('#loadingJobs').show();
                },
                success: function (msg, status, xhr) {

                },
                error: function (msg, status, xhr) {
                    $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                    $('#loadingJobs').hide();
                    $('#pendingJobs').show();
                },
                complete: function (data) {
                    $('#modal').modal('hide');
                    $('#loadingJobs').hide();
                    $('#pendingJobs').show();
                    if (view == 'cronjobs') {
                        reloadCronjobsTable();
                    } else if (view == 'instances') {
                        reloadCronjobsInstancesTable(id);
                    }
                    $('#' + switcher).attr('data-current', selected);
                    $.growl.notice({title: "Success", message: 'Cronjob' + (selected == 0 ? ' deactivated' : ' activated') +'.'});
                }

            });
        });

        /**
         * Catch the switch event when a cronjob is actiavted or deactivated, and send the request to the server.
         */
        $(document).on('click', '#send-switcher-active-all', function (e) {
            e.preventDefault();
            let switcher = $('#send-switcher-active-all').data('switcher');
            let selected = $(this).data('switcher-clicked');
            let view = $(this).data('view');
            let id = $('#' + switcher).data('cronjob_parent');
            $.ajax({
                url: '/settings/cron-job/activateAll',
                method: 'POST',
                data: {
                    active: selected,
                    cronjob_parent: id,
                    is_running: (selected === 1) ? 0 : selected
                },
                beforeSend: function () {
                    $('#pendingJobs').hide();
                    $('#loadingJobs').show();
                },
                success: function (msg, status, xhr) {

                },
                error: function (msg, status, xhr) {
                    $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                    $('#loadingJobs').hide();
                    $('#pendingJobs').show();
                },
                complete: function (data) {
                    $('#modal').modal('hide');
                    $('#loadingJobs').hide();
                    $('#pendingJobs').show();
                    if (view == 'cronjobs') {
                        reloadCronjobsTable();
                    } else if (view == 'instances') {
                        reloadCronjobsInstancesTable(id);
                    }
                    $('#' + switcher).attr('data-current', selected);
                    $.growl.notice({title: "Success", message: 'Cronjob' + (selected == 0 ? ' deactivated' : ' activated') +'.'});
                }

            });
        });

        /**
         * Handles the click on the search button in the cron instances view showing a list with the processed products
         */
        $('#cron_instances_search_button').on('click', function (e) {
            let criteria = $('#cron_instances_filter').val();
            let custom_view = $('#cron_instances_filter').attr('data-view');
            console.log(custom_view);
            if (criteria !== "") {
                $.ajax({
                    url: '../../cron-job/instances/products',
                    data: {
                        criteria: criteria,
                    },
                    method: 'GET',
                    beforeSend: function () {
                        $('#content').append(overlayDiv());
                    },
                    success: function (msg, status, xhr) {
                        $('#cron_instances_filter').css('border', '');
                        $('#cronjobs_instances_back_button').hide();
                        $('.history-header').append('<span data-icon="7" class="icon red right close-filter hide"' +
                            ' id="close_table_instances_products_container" data-view="'+custom_view+'"data-toggle="tooltip" data-placement="left"' +
                            ' title="" data-filter="filter_order" data-original-title="Close search"' +
                            ' style="display: inline;">\n' +
                            '</span>');
                        removeOverlayDiv();
                        if (custom_view == 'cronjobs_instances') {
                            $('#table_instances_container').hide();
                            $('#table_instances_products_container').append(msg);
                        } else if(custom_view == 'cronjobs_history') {
                            $('#product_detail_history').html(msg);
                            $('#container_table_product_dates').hide();
                            $('#product_detail_history').show();
                        }
                        $('#table-products').footable();
                        $('#table-products').footableCustom();
                    },
                    error: function (msg, status, xhr) {
                        removeOverlayDiv();
                        $.growl.error({title: "Error", message: msg.statusText});
                    },
                    complete: function (data) {
                        $('#cron_instances_filter').attr('data-original-title', 'Type a productID and press search to filter');

                    }
                });
            } else {
                $('#cron_instances_filter').css('border', 'thin solid #f5b9b9');
                $('#cron_instances_filter').attr('data-original-title', 'This field is required');
                $('#cron_instances_filter').tooltip('show');
            }

        });

        /**
         * Handle the click on the close button in the list with the processed products
         */
        $(document).on('click', '#close_table_instances_products_container', function (e) {
            let custom_view = $(this).attr('data-view');
            $(this).remove();
            if (custom_view == 'cronjobs_instances') {
                $('#cronjobs_instances_back_button').show();
                $('#table_instances_products_container').html('');
                $('#product_detail').html('');
                $('#table_instances_container').show('slow');
            } else if (custom_view == 'cronjobs_history') {
                $('#product_detail_history').html('');
                $('#container_table_product_dates').show();
                $('#product_detail_history').hide();
            }
        });

    }

    /**
     * Reload al the events at this file to prevent multiples executions.
     */
    function reload() {
        $(document).off('click', '#delete-cronjob-confirm');
        $(document).off('click', '.cron-input');
        $(document).off('input', '.cron-input');
        $(document).off('submit', '#cronjobs-form');
        $(document).off('click', '.delete-cron-job');
        $(document).off('click', '.switcher-active-list');
        $(document).off('click', '#send-switcher-active-list');
        $(document).off('click', '#send-switcher-active-all');
        $('#modal').off('hide.bs.modal');
        $('#table_crons').off('bind');
        $('#add-cron-job').off('click');
        $('.product-info').off('click');
        $('#close-product-detail').off('click');
        $('#close-cron-product-history').off('click');
        init();
    }

    /**
     * Reload Cronjobs list table via ajax.
     */
    function reloadCronjobsTable() {
        $.ajax({
            url: '/settings/cron-job',
            method: 'GET',
            data: {
                ajax: true
            },
            beforeSend: function () {
                $('#pendingJobs').hide();
                $('#loadingJobs').show();
            },
            success: function (msg, status, xhr) {

            },
            error: function (msg, status, xhr) {
                $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
                $('#loadingJobs').hide();
                $('#pendingJobs').show();
            },
            complete: function (data) {
                $('#loadingJobs').hide();
                $('#pendingJobs').show();
                $('#pendingJobs').html(data.responseText);
                reload();
                $('#table_crons').footable('footable');
                $('#table_crons').footableCustom();
            }

        });
    }

    /**
     * Reload Cronjobs Instances list table via ajax.
     * @param instance_id
     */
    function reloadCronjobsInstancesTable(instance_id) {
        $.ajax({
            url: '/settings/cron-job/instances-table/'+instance_id,
            method: 'GET',
            data: {
                ajax: true
            },
            beforeSend: function () {
                $('#content').append(overlayDiv());
            },
            success: function (msg, status, xhr) {

            },
            error: function (msg, status, xhr) {
                removeOverlayDiv();
                $.growl.error({title: "Error", message: 'The reload action failed.' + msg.statusText});
            },
            complete: function (data) {
                removeOverlayDiv();
                $('#table_instances_section').html(data.responseText);
                $('#table_cronjobs_instances').footable('footable');
                $('#table_cronjobs_instances').footableCustom();
            }

        });
    }

    /**
     *
     */
    function selection(class_part, value) {

        if (isValidChareacter(value, class_part)) {
            $('#part-' + class_part).addClass('part-selected');
            $('#part-' + class_part).removeClass('part-error');
            $('.info-part').removeClass('part-info-error');
            $('.info-part').removeClass('part-info-selected');
            if ($('.info-part-' + class_part).text().trim() !== '') {
                $('.info-part-' + class_part).addClass('part-info-selected');
            }
        } else {
            $('#part-' + class_part).removeClass('part-selected');
            $('.info-part-' + class_part).removeClass('part-info-selected');
            $('.info-part-' + class_part).addClass('part-info-error');
            $('#part-' + class_part).addClass('part-error');
            $('.info-part-' + class_part).text('Invalid expresion.');
        }
    }

    /**
     * Checks based on the selected field if the data entered is valid for the expression of a cron job.
     * @param char
     * @param selectionStart
     * @returns {*}
     */
    function isValidChareacter(char, selectionStart) {
        let regex = '';
        switch (selectionStart) {
            case 'cron-minute':
                regex = /(^\*$)|^(?:[0-5]?[0-9]|[1-9])(?:[/,\-](?:[0-5]?[0-9]|[1-9]))?$/;
                break;
            case 'cron-hour':
                regex = /(^\*$)|^(?:2[0-3]|[01]?[0-9])(?:[/,\-](?:2[0-3]|[01]?[0-9]))?$/;
                break;
            case 'cron-day-month':
                regex = /(^\*$)|^(?:0?[1-9]|[1-2][0-9]|3[0-1])(?:[/,\-](?:0?[1-9]|[1-2][0-9]|3[0-1]))?$/
                break;
            case 'cron-month':
                regex = /(^\*$)|^(?:1[0-2]|[1-9])(?:[/,\-](?:1[0-2]|[1-9]))?$/;
                break;
            case 'cron-day-week':
                regex = /(^\*$)|^(?:[0-7])(?:[/,\-](?:[0-7]))?$/;
                break;
        }
        addMessageParsedToHtml(char, selectionStart);
        return regex.test(char);
    }

    /**
     * Shows an information message depending on the field entered and the expression.
     * @param expression
     * @param class_part
     */
    function parseCronExpresion(expression, class_part) {
        let regex, regex1, regex2, regex3, regex4, msg = '';
        switch (class_part) {
            case 'cron-minute':
                regex = /^(?:[0-9]|[1-5][0-9])$/;
                regex1 = /(^\*$)/;
                regex2 = /^(?:[0-5]?[0-9]|59)\-(?:[0-5]?[0-9]|59)$/;
                regex3 = /^(?:[0-5]?[0-9]|59),(?:[0-5]?[0-9]|59)$/;
                regex4 = /^(?:[0-5]?[0-9]|59)\/(?:[0-5]?[0-9]|59)$/;
                if (regex.test(expression)) {
                    msg = 'minute ' + expression;
                } else if (regex1.test(expression)) {
                    msg = 'every minute';
                } else if (regex2.test(expression)) {
                    let values = expression.split('-');
                    msg = 'every minute from ' + values[0] + ' through ' + values[1];
                } else if (regex3.test(expression)) {
                    let values = expression.split(',');
                    msg = 'minute ' + values[0] + ' and ' + values[1];
                } else if (regex4.test(expression)) {
                    let values = expression.split('/');
                    msg = 'every ' + values[1] + 'minute from  ' + values[0] + ' through 59.';
                }
                break;
            case 'cron-hour':
                regex = /^(?:[0-9]|1[0-9]|2[0-3])$/;
                regex1 = /(^\*$)/;
                regex2 = /^(?:2[0-3]|[01]?[0-9])\-(?:2[0-3]|[01]?[0-9])$/;
                regex3 = /^(?:2[0-3]|[01]?[0-9]),(?:2[0-3]|[01]?[0-9])$/;
                regex4 = /^(?:2[0-3]|[01]?[0-9])\/(?:2[0-3]|[01]?[0-9])$/;
                if (regex.test(expression)) {
                    msg = 'past hour ' + expression;
                } else if (regex1.test(expression)) {
                    msg = '';
                } else if (regex2.test(expression)) {
                    let values = expression.split('-');
                    msg = 'past every hour from ' + values[0] + ' through ' + values[1];
                } else if (regex3.test(expression)) {
                    let values = expression.split(',');
                    msg = 'past hour ' + values[0] + ' and ' + values[1];
                } else if (regex4.test(expression)) {
                    let values = expression.split('/');
                    msg = 'past every ' + values[1] + ' hour from  ' + values[0] + ' through 23.';
                }
                break;
            case 'cron-day-month':
                regex = /^(?:[1-9]|[12][0-9]|3[01])$/;
                regex1 = /(^\*$)/;
                regex2 = /^(?:3[01]|[012]?[0-9])\-(?:3[01]|[012]?[0-9])$/;
                regex3 = /^(?:3[01]|[012]?[0-9]),(?:3[01]|[012]?[0-9])$/;
                regex4 = /^(?:3[01]|[012]?[0-9])\/(?:3[01]|[012]?[0-9])$/;
                if (regex.test(expression)) {
                    msg = 'on day-of-month ' + expression;
                } else if (regex1.test(expression)) {
                    msg = '';
                } else if (regex2.test(expression)) {
                    let values = expression.split('-');
                    msg = 'on every day-of-month from ' + values[0] + ' through ' + values[1];
                } else if (regex3.test(expression)) {
                    let values = expression.split(',');
                    msg = 'on day-of-month ' + values[0] + ' and ' + values[1];
                } else if (regex4.test(expression)) {
                    let values = expression.split('/');
                    msg = 'past every ' + values[1] + ' day-of-month from  ' + values[0] + ' through 23.';
                }
                break;
            case 'cron-month':
                regex = /^(?:[1-9]|1[0-2])$/;
                regex1 = /(^\*$)/;
                regex2 = /^(?:1[012]|[0-9])\-(?:1[012]|[1-9])$/;
                regex3 = /^(?:1[012]|[0-9]),(?:1[012]|[1-9])$/;
                regex4 = /^(?:1[012]|[0-9])\/(?:1[012]|[1-9])$/;
                if (regex.test(expression)) {
                    let month = getMonthName(expression);
                    msg = 'in ' + month;
                } else if (regex1.test(expression)) {
                    msg = '';
                } else if (regex2.test(expression)) {
                    let values = expression.split('-');
                    msg = 'in every month from ' + values[0] + ' through ' + values[1];
                } else if (regex3.test(expression)) {
                    let values = expression.split(',');
                    msg = 'in ' + values[0] + ' and ' + values[1];
                } else if (regex4.test(expression)) {
                    let values = expression.split('/');
                    msg = 'in every ' + values[1] + 'month from ' + values[0] + ' through December.';
                }
                break;
            case 'cron-day-week':
                regex = /^[0-7]$/;
                regex1 = /(^\*$)/;
                regex2 = /^(?:[0-7])\-(?:[0-7])$/;
                regex3 = /^(?:[0-7]),(?:[0-7])$/;
                regex4 = /^(?:[0-7])\/(?:[0-7])$/;

                if (regex.test(expression)) {
                    let day = getWeekDayName(expression);
                    msg = 'on ' + day;
                } else if (regex1.test(expression)) {
                    msg = '';
                } else if (regex2.test(expression)) {
                    let values = expression.split('-');
                    msg = 'in every month from ' + values[0] + ' through ' + values[1];
                } else if (regex3.test(expression)) {
                    let values = expression.split(',');
                    msg = 'in ' + values[0] + ' and ' + values[1];
                } else if (regex4.test(expression)) {
                    let values = expression.split('/');
                    msg = 'on every day-of-week from ' + values[1] + ' through ' + values[0];
                }
                break;
        }
        return msg;
    }

    /**
     * Add the message parsed to html.
     * @param char
     * @param class_part
     */
    function addMessageParsedToHtml(char, class_part) {
        let msg = parseCronExpresion(char, class_part);
        $('.info-part-' + class_part).text(msg);
        $('.info-validation').hide();
        $('.part-' + class_part).show();
    }

    /**
     * Obtains the name of the week in English given a number from 0 to 7. For that we take a date that is the beginning of the week (Sunday 09-01-2024).
     * @param number
     * @returns {string}
     */
    function getWeekDayName(number) {
        let date = new Date('01-09-2024');
        date.setDate(parseInt(number)); // Set week day number to get de name.
        return date.toLocaleDateString('en-US', {weekday: 'long'});
    }

    /**
     * Gets the name of a month given a number from 1 to 12.
     * @param number
     * @returns {string}
     */
    function getMonthName(number) {
        let date = new Date();
        date.setMonth(parseInt(number - 1)); // Subtract 1 because the months go from 0-11 in javascript
        return date.toLocaleDateString('en-US', {month: 'long'});
    }

    /**
     * Print a loading overlay
     * @returns {string}
     */
    function overlayDiv() {
        return '<div id="overlay_footable" class="footable_overlay"><div class="footable_loading-container"><div class="footable_loading"></div><p>loading...</p></div></div>'
    }

    function removeOverlayDiv() {
        $('#overlay_footable').remove();
    }


});

