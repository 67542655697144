// Global Variables
if(window.location.href.indexOf("qa") > -1) {
    var siteUrl = "https://qa.primoprint.com";
    var adminUrl = "https://qadashboard.primoprint.com";
    var notificationIcon = siteUrl+'/images/icon.jpg';
    var intercomIcon = adminUrl+'/images/intercom-pp-icon.png';
    var uploadURL = "https://qaupload.primoprint.com/";
    var pusherChannel = 'private-general';
    var global_site = 'primoprint';
}else {
    var siteUrl = "https://www.primoprint.com";
    var adminUrl = "https://dashboard.primoprint.com";
    var notificationIcon = siteUrl+'/images/icon.jpg';
    var intercomIcon = adminUrl+'/images/intercom-pp-icon.png';
    var uploadURL = "https://upload.primoprint.com/";
    var pusherChannel = 'private-general';
    var global_site = 'primoprint';
}

