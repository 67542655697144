$.fn.unlock = function() {
    return this.each(function() {
        var btn = $(this);
        var timer = '';

        // btn.attr('disabled','disabled');
        btn.addClass('restricted');
        btn.css('position','relative');

        btn.on('mouseenter', function(){
            var thisBtn = $(this);
            thisBtn.prepend('<span class="expand" style="width:0%;height:100%;background:rgba(0,0,0,0.1);position:absolute;top:0;left:0;"></span>');

            thisBtn.children('span').animate({width: '100%'}, 1500);
            thisBtn.css('cursor','wait');

            timer = setTimeout(function(){
                thisBtn.css('cursor','pointer');
                thisBtn.children('span').css('opacity','0.0');
                thisBtn.removeClass('restricted').addClass('btn-primary granted');
            }, 1500);
        });

        btn.on('mouseleave', function(){
            var thisBtn = $(this);

            clearTimeout(timer);
            thisBtn.css('cursor','pointer');
            thisBtn.children('span').remove();
            thisBtn.removeClass('btn-primary granted').addClass('restricted');
        });
    });
};