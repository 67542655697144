/*!
 * FooTable Custom - for Primoprint
 * Version : 1.0
 *
 * Requires jQuery - http://jquery.com/
 *
 * Copyright 2022 Dualbootpartners
 *
 * Date: 29 Nov 2022
 */
(function ($, w, undefined) {
    w.footableCustom = {
        options: {}
    }
    var instanceCount = 0;
    /**
     *  Constructor
     *  Builds an instance of footableCustom to manage all the events over the table. The params 'data-values',
     *  'data-fields', 'data-paginattion' should be formated in json and encoded as base64
     * @returns {*}
     */
    $.fn.footableCustom = function () {
        let id_table = $(this).attr("id");
        if (typeof id_table !== "undefined") {
            var options = {
                values: typeof $('#' + id_table).data("values") !== "undefined" ? decryptJsonArray($('#' + id_table).data("values")) : [5, 10, 15, 30, 50],
                page_size: $('#' + id_table).data('page-size'),
                active_pagination: '#' + id_table + ' li.footable-page.active > a',
                id_table_simple: id_table,
                id_table: '#' + id_table,
                filter: '#' + id_table + '_filter',
                _noentries: '#' + id_table + '_noentries',
                _entries: '#' + id_table + '_entries',
                total_entries: '#' + id_table + ' tbody tr',
                total_entries_visible: '#' + id_table + ' tbody tr:visible',
                total_rows: '#' + id_table + '_total_entries',
                rows_page: '#' + id_table + '_rows_page',
                current_page: '#' + id_table + '_current_page',
                change_page_size: '#' + id_table + '_change_page_size',
                search_button: '#' + id_table + '_search_button',
                id_paginations: '#' + id_table + '_paginations',
                container_table: '#container_' + id_table,
                container_table_simple: 'container_' + id_table,
                overlay: id_table + '_overlay',
                colspan: typeof $('#' + id_table).data("colspan") !== "undefined" ? $('#' + id_table).data("colspan") : 5,
                colspan2: typeof $('#' + id_table).data("colspan2") !== "undefined" ? $('#' + id_table).data("colspan2") : 1,
                fields: typeof $('#' + id_table).data("fields") !== "undefined" ? decryptJsonArray($('#' + id_table).data("fields")) : [],
                paginattion: typeof $('#' + id_table).data("paginattion") !== "undefined" ? decryptJsonArray($('#' + id_table).data("paginattion")) : "undefined",
            }
            // options = options || {};
            var o = $.extend(true, {}, w.footableCustom.options, options); //merge user and default options
            return this.each(function () {
                instanceCount++;
                var footablecustom = new FootableCustom(this, o, instanceCount);
                footablecustom.initFootableCustom();
            });
        }
    };

    function FootableCustom(t, o, id) {
        var ftc = this;
        ftc.id = id;
        ftc.table = t;
        ftc.options = o;

        /**
         * Update the footer table information
         */
        ftc.updateTableInfo = function () {
            if (($(ftc.options.active_pagination).length !== 0 || ftc.options.paginattion !== "undefined") && ftc.options.paginattion.total !== 0) {
                if (ftc.options.paginattion !== "undefined") {
                    //if has pagination via ajax
                    var total_entries = ftc.options.paginattion.total;
                    var current_page = ftc.options.paginattion.current_page;
                    var page_size = ftc.options.paginattion.per_page;
                } else {
                    //if the pagination is only in the client front
                    var total_entries = ftc.getCantRows();
                    var current_page = parseInt($(ftc.options.active_pagination).text());
                    var page_size = ($(ftc.options.change_page_size).val() !== undefined) ? $(ftc.options.change_page_size).val() : ftc.options.page_size;
                }
                let rows_page = page_size * current_page;
                if (rows_page > total_entries) {
                    rows_page = total_entries
                }
                $(ftc.options._noentries).hide();
                $(ftc.options.total_rows).text(total_entries);
                $(ftc.options.rows_page).text(rows_page);
                $(ftc.options.current_page).text(current_page);
                $(ftc.options._entries).show();
            } else {
                $(ftc.options._entries).hide();
                $(ftc.options._noentries).show();
            }
        }
        /**
         *
         * @returns {number}
         */
        ftc.getCantRows = function() {
            let cant = 0;
            let info = $(ftc.options.id_table).data('footable').pageInfo;
            for (i = info.pages.length - 1; i >=0 ; i--){
                cant += info.pages[i].length;
            }
            return cant;
        }

        /**
         *
         * @param href
         */
        ftc.reloadTable = function (href) {
            let filter_value = $(ftc.options.filter).val();
            let data_query = undefined;
            if ($(ftc.options.id_table).data('query') !== undefined) {
                data_query = $(ftc.options.id_table).data('query');
                href+= '&' + data_query;
            }
            $.ajax({
                url: href + '&ajax=true&perPage=' + ftc.options.page_size,
                method: 'GET',
                data: {
                    fields: ftc.options.fields,
                    query: filter_value
                },
                beforeSend: function () {
                    $(ftc.options.id_table).append('<div id="' + ftc.options.overlay + '" class="footable_overlay"><div class="footable_loading-container"><div class="footable_loading"></div><p>loading...</p></div></div>');
                    $('#' + ftc.options.overlay).show();
                },
                success: function (msg, status, xhr) {
                    $(ftc.options.container_table).html(xhr.responseText);
                    ftc.options.paginattion = JSON.parse(atob($(ftc.options.id_table).data('paginattion')));
                    $(ftc.options.id_table).data('page-size', ftc.options.page_size)
                    $(ftc.options.id_table).footable();
                    if (data_query !== undefined) {
                       $(ftc.options.id_table).attr('data-query', data_query);
                    }
                    ftc.initFootableCustom();
                    ftc.updateTableInfo();
                    $(ftc.options.filter).val(filter_value);
                },
                error: function (msg, status, xhr) {
                    console.log(msg, status, xhr);
                }
            })
        }

        /**
         *
         * @param fields
         */
        ftc.initFootableCustom = function () {
            ftc.buildHtmlTableHeader();
            ftc.buildHtmlTableFooter();
            if ($(ftc.options.id_table).data('footable') === undefined) {
                $(ftc.options.id_table).footable();
            }
            $(ftc.options.id_table).data('footable').redraw();
            $(ftc.options.id_table).trigger('footable_initialized');
            //when footable built on modal tables, destination is not from browser location
            let destinateLocation =  $(location).attr('href') + '?';
            if ($(ftc.options.id_table).data('location') !== undefined) {
                destinateLocation =  $(ftc.options.id_table).data('location') + '?'
                if ($(ftc.options.id_table).data('query') !== undefined) {
                    destinateLocation += '&' +$(ftc.options.id_table).data('query');
                }
            }

//Filter
            $(ftc.options.change_page_size).val(ftc.options.page_size);
            $(ftc.options.id_table).bind({
                'footable_paginated': function (e) {
                    return ftc.updateTableInfo();
                },
                'footable_filtered': function (e) {
                    return ftc.updateTableInfo();
                },
            });


            $(ftc.options.change_page_size).change(function (e) {
                e.preventDefault();
                var pageSize = $(this).val();
                ftc.options.page_size = pageSize;
                $(ftc.options.id_table).data('page-size', pageSize);
                if (ftc.options.paginattion  === "undefined") {
                    $(ftc.options.id_table).trigger('footable_initialized');
                }
                ftc.updateTableInfo();
                if (ftc.options.paginattion !== "undefined") {
                    ftc.reloadTable(destinateLocation);
                }
            });
            //Set 'enter' key to trigger search button click
            $(ftc.options.filter).keypress(function (e) {
                var key = e.which;
                if (key == 13)  // the enter key code
                {
                    $(ftc.options.search_button).click();
                    return false;
                }
            });
            $(ftc.options.search_button).on('click', function () {
                ftc.reloadTable(destinateLocation);
            });
//Paginate
            $(ftc.options.active_pagination).ready(function () {
                ftc.updateTableInfo();
            });
            $(ftc.options.container_table).off().on('click', 'ul.paginations > li.footable-page > a', function (e) {
                e.preventDefault();
                let href = this.href;
                if ($(ftc.options.id_table).data('query') !== undefined) {
                    href =  this.href + '&' +$(ftc.options.id_table).data('query');
                }
                ftc.reloadTable(href);
            });
        }

        /**
         *
         */
        ftc.buildHtmlTableFooter = function () {
            var pag = '<div class="pagination pagination-centered"></div>';
            if (ftc.options.paginattion  !== "undefined") {
                if (ftc.options.paginattion.total <= ftc.options.page_size) {
                    pag = '';
                } else {
                    let html = $(ftc.options.container_table + " .paginations").detach();
                    pag = (html.length  !== 0) ? html[0].outerHTML : '';
                }
            }
            var footer = '<tfoot class="hide-if-no-paging">\n' +
                '<tr>\n' +
                '    <td id = "' + ftc.options.id_table_simple + '_entries" colspan="' + ftc.options.colspan2 + '">\n' +
                '        Showing <span class="bold" id="' + ftc.options.id_table_simple + '_current_page"></span>\n' +
                '        to <span class="bold" id="' + ftc.options.id_table_simple + '_rows_page"></span>\n' +
                '        of <span class="bold" id="' + ftc.options.id_table_simple + '_total_entries"></span> entries\n' +
                '    </td>\n' +
                '    <td id = "' + ftc.options.id_table_simple + '_noentries" colspan="1" style="display: none;">\n' +
                '        No entries found.\n' +
                '    </td>\n' +
                '    <td colspan="' + ftc.options.colspan + '">\n' + pag +
                '    </td>\n' +
                '</tr>\n' +
                '</tfoot>';
            $(ftc.options.id_table + ' > tbody').after(footer);

        }

        /**
         *
         */
        ftc.buildHtmlTableHeader = function () {
            $(ftc.options.id_table).before('<div id="' + ftc.options.container_table_simple + '"></div>');
            $(ftc.options.id_table).appendTo($(ftc.options.container_table));
            if (ftc.options.paginattion  !== "undefined") {
                $(ftc.options.id_paginations).appendTo($(ftc.options.container_table));
                var text_msg = 'Type and press search to filter';
                var button = '<button id="' + ftc.options.id_table_simple + '_search_button" class="btn btn-primary footable-search-button" data-icon=";"></button>';
                var totalRows = ftc.options.paginattion.total;
            } else {
                var text_msg = 'Type to filter';
                var button = '';
                var totalRows = ftc.getCantRows();
            }

            var header = '<div style="display: inline">\n' + (
                $(ftc.options.id_table).data('filter') ?
                '    <div class="footable_custom_filter">\n' +
                '        <input id="' + ftc.options.id_table_simple + '_filter" type="text" class="footable-search-input" placeholder="' + text_msg + '">\n' + button +
                '    </div>\n' : '' ) +
                ((totalRows > 5) ?
                '    <div style="float: right">\n' +
                '        <label>\n' +
                '            Rows:\n' +
                '            <select id="' + ftc.options.id_table_simple + '_change_page_size" class="footable_change_page_size" >\n' +
                '                <option value="5">5</option>\n' +
                '                <option value="10">10</option>\n' +
                '                <option value="15">15</option>\n' +
                '                <option value="30">30</option>\n' +
                '                <option value="50">50</option>\n' +
                '            </select>\n' +
                '        </label>\n' +
                '    </div>\n' : '' )+
                '</div>';
            $(ftc.options.container_table).prepend(header);
        }
    }
    /**
     *
     * @param encryptJsonArray
     * @returns {any}
     */
    function decryptJsonArray(encryptJsonArray) {
        return JSON.parse(atob(encryptJsonArray));
    }
    /**
     *
     * @param jsonArray
     * @returns {String}
     */
    function encryptJsonArray(jsonArray) {
        return btoa(JSON.stringify(jsonArray));
    }
})(jQuery, window);