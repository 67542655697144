var node;
var pusher;
var generalChannel;
var orderChannel;

$(function() {

  node = $('div.node');
  pusher = new Pusher('5ac79d00670f8008b3ac', {encrypted: true, authEndpoint: '/ajax/pusher/auth'});

  pusher.connection.bind( 'error', function( err ) {
      node.slideDown('fast');
      $('.signal').removeClass('yellow green').addClass('red');
      General.alert('Live Updates Connection Error');
      console.log(err);
  });

  pusher.connection.bind( 'connecting', function() {
      node.slideDown('fast');
      $('.signal').removeClass('red green').addClass('yellow');
  });

  pusher.connection.bind( 'unavailable', function( err ) {
      node.slideDown('fast');
      $('.signal').removeClass('yellow green').addClass('red');
      General.alert('Live Updates Unavailable');
      console.log(err);
  });

  pusher.connection.bind( 'connected', function( err ) {
      node.slideUp('fast');
      $('.signal').removeClass('red yellow').addClass('green');
  });

  pusher.connection.bind( 'failed', function( err ) {
      node.slideDown('fast');
      $('.signal').removeClass('yellow green').addClass('red');
      console.log(err);
  });

  pusher.connection.bind( 'disconnected', function( err ) {
      node.slideDown('fast');
      $('.signal').removeClass('yellow green').addClass('red');
      General.alert('Live Updates Disconnected');
      console.log(err);
  });

  // presenceChannel = pusher.subscribe('presence-users');
  // presenceChannel.bind('enter', function(member) {
  //     // for example:
  //     General.alert(member);
  // });

  generalChannel = pusher.subscribe(pusherChannel);

      generalChannel.bind('client-updateRow', function(data){
          var id = data.id;
          $.get('/ajax/order/build-order-row/'+id, function(data){
              if($('.jlist').length){
                  if($('.jlist').hasClass('personal')){
                      $('tr[data-id='+id+']').replaceWith(data.partial);
                  }else{
                    if($('.jlist').hasClass(data.status)){
                        $('tr[data-id='+id+']').replaceWith(data.full);
                    }else{
                        $('tr[data-id='+id+']').remove();
                    }
                  }
                  $('.footable').footable().resize();
                  General.tips();
              }
          });
      });

      generalChannel.bind('client-msg', function(data){
          General.alert(data.msg);
      });

      generalChannel.bind('client-notification', function(data){
          Notify.send(data.url, data.title, data.desc);
      });

      generalChannel.bind('client-notificationIntercom', function(data){
          Notify.sendIntercom(data.url, data.title, data.desc);
      });

      generalChannel.bind('client-log', function(data){
          console.log('test');
          $('.logTarget').prepend(data.row);
          $('.logTarget tr:first').slideDown('slow');
          setTimeout(function(){
              $('.highlight').removeClass('highlight');
          }, 500);
      });

      generalChannel.bind('client-showhide', function(data){
          var addTo = data.addTo;
          var id = data.id;

          $('tr[data-id='+id+']').fadeOut('fast', function(){ $(this).remove(); });

          if(addTo){
              $.get('/ajax/order/build-order-row/'+id, function(data){
                  $('table.'+addTo+' tbody').append(data.full);
                  $('.noorders').remove();
                  General.tips();
              });
          }
      });

      generalChannel.bind('client-rebuild', function(data){
          $.get('/ajax/order/rebuild-counts', function(data){
                var counts = data;

                if(counts){
                    node.slideUp('fast');

                    for (var val in counts){
                        if (typeof counts[val] !== 'function') {
                             $('[data-tag='+val+'] span').html(counts[val]);
                        }
                    }
                }else{
                    node.slideDown('fast');
                }
          });
      });

  personalChannel = pusher.subscribe(userEmail);

    personalChannel.bind('user-modal', function(data){
        $('#modal').modal({
            remote: '/ajax/modal/customer/'+data.id
        });
        setTimeout(function(){
            $('#modal').focus();
        }, 250);
        Notify.send('https://dashboard.primoprint.com/', 'On the Phone', 'User profile loaded for customer currently on the phone');
        General.alert('On the Phone:<br />'+data.name+'<br />'+data.email, 10000);
        document.title = document.title + ' - Notification: '+data.id;
    });

    let pusherCronjobs = new Pusher('a862aef1a40b470b323a', {
        cluster: 'mt1'
    });
    let channel = pusherCronjobs.subscribe('test-channel');
    channel.bind("fourover-product-update-event", function(data) {
        let dataInfo = JSON.parse(data.data);
        $('.progressbar_'+dataInfo.cron_instance).find('div').css('width', dataInfo.percentage+'%');
        $('.progressbar_'+dataInfo.cron_instance).each(function (e) {
            let text = '<strong>current_product</strong>: '+dataInfo.current_product+'</br>' +
                       '<strong>product_code</strong>: '+dataInfo.current_product_code+'</br>' +
                        dataInfo.percentage + '<strong>%</strong>'+'</br>' +
                       '<strong>current_record</strong>: '+dataInfo.current_record;
            $(this).attr('data-original-title', text);
        });
    });
    channel.bind("generic-cron-event", function(data) {
        let dataInfo = JSON.parse(data.data);
        $('.progressbar_'+dataInfo.cron_instance).find('div').css('width', dataInfo.percentage+'%');
        $('.progressbar_'+dataInfo.cron_instance).each(function (e) {
            let text = dataInfo.percentage + '<strong>%</strong>';
            $(this).attr('data-original-title', text);
        });
    });

  function addCommas(nStr)
  {
      nStr += '';
      x = nStr.split('.');
      x1 = x[0];
      x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return x1 + x2;
  }

});

var socket;
var node;

// @codekit-append "dropzone.js", "socketio.js", "bootstrap.js", "hogan.js", "typeahead.js", "jRespond.min.js", "jquery.easing.1.3.js", "jquery.unlock.js", "footable.js", "footable.sortable.js", "footable.filter.js", "footable.paginate.js", "charts.js", "smoothie.js", "moment.js", "multiselect.js", "bootstrap-tour.min.js", "base.js", "general.js", "dashboard.js", "sidebar.js", "order.js", "uploads.js", "notifications.js";
